import React, { useState } from 'react';
import { styled, IconButton, Theme, Menu, MenuItem } from '@mui/material';

import { ReactComponent as Bars } from 'content/images/icons/bars.svg';
import { ReactComponent as Times } from 'content/images/icons/times.svg';
import NavButtons from 'components/Nav/Buttons';

const Hamburger = styled(Bars)(({ theme }: { theme: Theme }) => ({
	height: 25,
	fill: 'white',
}));

const Close = styled(Times)(({ theme }: { theme: Theme }) => ({
	height: 25,
	fill: 'white',
}));

const Buttons = styled(NavButtons)({
	flexDirection: 'column',
});

const MobileMenu = styled(Menu)({
	width: '100%',
	alignItems: 'center',
	zIndex: 98,
});

interface Props {
	anchorTop: number;
	anchorLeft: number;
	containerRef: React.MutableRefObject<HTMLDivElement>;
}

export default (props: Props) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const buttonArray = [<Buttons key="mobileMenuButtons" fullWidth />];

	console.log(buttonArray);

	const renderListItem = (element: React.ReactElement, key?: string) => (
		<MenuItem onClick={handleClose} key={key}>
			{element}
		</MenuItem>
	);

	const renderMenuItems = () =>
		buttonArray.map((element: React.ReactElement, index) =>
			renderListItem(element, `${element}${index}`),
		);

	const renderButton = () => {
		if (anchorEl === null) {
			return (
				<IconButton
					aria-controls="hamburger-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<Hamburger />
				</IconButton>
			);
		}
		return (
			<IconButton aria-controls="hamburger-menu" aria-haspopup="true" onClick={handleClose}>
				<Close />
			</IconButton>
		);
	};

	return (
		<>
			{renderButton()}
			<MobileMenu
				id="hamburger-menu"
				keepMounted
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={{ top: props.anchorTop - 27, left: props?.anchorLeft }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{renderMenuItems()}
			</MobileMenu>
		</>
	);
};
