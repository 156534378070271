import * as React from 'react';

import { CssBaseline, styled } from '@mui/material';

const Root = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100vh',
	position: 'relative',
});

interface Props {
	children: React.ReactElement | React.ReactChildren | React.ReactElement[];
	className?: string;
}

export default (props: Props) => (
	<>
		<CssBaseline />
		<Root>{props?.children}</Root>
	</>
);
