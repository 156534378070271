import React from 'react';
import { styled } from '@mui/material';
import { Button } from 'gatsby-theme-material-ui';

import type { ButtonProps } from 'components/Button/types';

const GatsbyButton = styled(Button, {
	shouldForwardProp: (prop) =>
		prop !== 'short' && prop !== 'fullWidth' && prop !== 'center' && prop !== 'noPadding',
})<ButtonProps>(({ theme, short, fullWidth, center, noPadding }) => ({
	padding: noPadding ? 0 : short ? theme.spacing(1, 3) : theme.spacing(2, 3),
	width: fullWidth ? '100%' : 'fit-content',
	whiteSpace: 'nowrap',
	minWidth: 'fit-content',
	alignSelf: center ? 'center' : 'inherit',
	'&: .Mui-disabled': {
		backgroundColor: `${theme.palette.grey[700]} !important`,
	},
}));

export interface InternalProps extends ButtonProps {
	to?: string;
}

export default (props: InternalProps) => (
	<GatsbyButton
		color={props.color}
		variant={props?.variant || 'contained'}
		fullWidth={props?.fullWidth}
		type={props?.type}
		onClick={props?.onClick}
		disabled={props?.disabled}
		to={props?.to}
		startIcon={props?.icon}
		disableRipple={props?.disableRipple}
		className={props?.className}
		center={props?.center}
		noPadding={props?.noPadding}
	>
		{props.children}
	</GatsbyButton>
);
