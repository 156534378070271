import React from 'react';
import Helmet from 'react-helmet';

import Root from 'layouts/Root';
import Main from 'layouts/Main';
import Footer from 'components/Footer';
import Nav from 'components/Nav';

import { ThemeProvider } from '@mui/material/styles';
import theme from '../../plugins/custom-mui-theme/theme/index';

interface Props {
	children: React.ReactElement | React.ReactChildren | React.ReactElement[];
	metaTitle: string;
	metaDescription: string;
	title: string;
	noindex?: boolean;
	darkNav?: boolean;
}

export default (props: Props) => (
	<ThemeProvider theme={theme}>
		<Root>
			<Helmet
				title={props?.title}
				meta={[{ name: props?.metaTitle, content: props?.metaDescription }]}
			>
				<meta name="viewport" content="width=device-width, minimum-scale=1" />
			</Helmet>
			<Nav dark={props?.darkNav} />
			<Main>{props?.children}</Main>
			<Footer />
		</Root>
	</ThemeProvider>
);
