import React from 'react';

import Internal, { InternalProps } from 'components/Button/Internal';
import External, { ExternalProps } from 'components/Button/External';

interface Props extends InternalProps, ExternalProps {
	external?: boolean;
}

export default (props: Props) => {
	const {
		color,
		className,
		type,
		onClick,
		variant,
		fullWidth,
		short,
		disabled,
		icon,
		disableRipple,
		center,
		to,
		href,
		noPadding,
	} = props;

	if (props?.external) {
		return (
			<External
				{...{
					color,
					className,
					type,
					onClick,
					variant,
					fullWidth,
					short,
					disabled,
					icon,
					disableRipple,
					center,
					href,
					noPadding,
				}}
			>
				{props?.children}
			</External>
		);
	}

	return (
		<Internal
			{...{
				color,
				className,
				type,
				onClick,
				variant,
				fullWidth,
				short,
				disabled,
				icon,
				disableRipple,
				center,
				to,
				noPadding,
			}}
		>
			{props?.children}
		</Internal>
	);
};
