import { createTheme } from '@mui/material/styles';

import palette from './palette';

import EffraRegular from '../fonts/effraregular.woff2';

const defaultTheme = createTheme();

export default createTheme({
	palette,
	typography: {
		h1: {
			fontSize: '2.4rem',
			color: palette.text.hint,
			fontWeight: 600,
			[defaultTheme.breakpoints.up('sm')]: {
				fontSize: '5rem',
			},
		},
		h2: {
			fontWeight: 600,
			color: palette.secondary.main,
			fontSize: '1.6rem',
			[defaultTheme.breakpoints.up('sm')]: {
				fontSize: '3.5rem',
			},
		},
		h3: {
			fontWeight: 600,
			fontSize: '1rem',
			[defaultTheme.breakpoints.up('sm')]: {
				fontSize: '1.5rem',
			},
		},
		h4: {
			fontWeight: 400,
			fontSize: '1rem',
			color: palette.primary.main,
			[defaultTheme.breakpoints.up('sm')]: {
				fontSize: '1.1rem',
			},
		},
		h5: {
			fontWeight: 400,
			fontSize: '1rem',
		},
		subtitle1: {
			fontSize: '1.2rem',
			color: palette.text.hint,
		},
		subtitle2: {
			color: palette.text.hint,
			opacity: 0.7,
			fontWeight: 400,
		},
		body1: {
			color: palette.text.primary,
			fontWeight: 400,
			fontFamily: ['Effra Regular', 'sans-serif'].join(','),
		},
	},
	breakpoints: {
		values: {
			xs: 400,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
				@font-face {
					font-family: 'Effra Regular';
					src: url(${EffraRegular});
				}
			`,
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '5px',
					fontSize: '0.9rem',
					textTransform: 'capitalize',
					fontFamily: 'inherit',
				},
				containedPrimary: {
					color: 'white',
					boxShadow: 'none',
				},
				containedSecondary: {
					color: palette.text.primary,
					boxShadow: 'none',
					backgroundColor: 'white',
				},
				text: {
					textTransform: 'capitalize',
				},
				textPrimary: {
					color: 'white',
				},
				textSecondary: {
					color: palette.text.primary,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					fontWeight: 'bold',
					'&:visited': {
						color: palette.text.primary,
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				shrink: {
					// display: 'none'
					// color: `#000 !important`,
					// background: '#fff',
					// marginBottom: '4px',
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				fontFamily: ['Effra Regular', 'sans-serif'].join(','),
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					// color: 'white',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					// borderColor: 'white',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					// color: 'white',
				},
			},
		},
	},
});
