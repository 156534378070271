export default {
	primary: {
		main: '#161925',
	},
	secondary: {
		main: '#23395B',
	},
	text: {
		primary: '#161925',
		secondary: '#AFAEAE',
		hint: '#7D8CA3',
	},
	background: {
		default: '#F1F1F1',
		paper: '#fff',
	},
	blue: {
		dark: '#1D3557',
	},
	footer: {
		main: '#161925',
	},
	calloutColors: {
		purple: {
			main: '#540D6E',
			light: '#F8E4FF',
		},
		orange: {
			main: '#F86624',
			light: '#FFDBCB',
		},
		yellow: {
			main: '#FFC759',
			light: '#FFF3DB',
		},
		green: {
			main: '#59CD90',
			light: '#E6FFF2',
		},
	},
	tertiary: {
		main: '#C2C1C2',
		dark: '#878187',
	},
	disabled: {
		light: '#E9E9E9',
		main: '#D1D1D1',
		dark: '#AFAEAE',
	},
	success: {
		main: '#4A7C59',
	},
	failure: {
		main: '#ED474A',
	},
};
