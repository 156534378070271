import * as React from 'react';
import { styled } from '@mui/material';

const MainRoot = styled('main')({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
});

interface Props {
	className?: string;
	children: React.ReactElement | React.ReactChildren | React.ReactElement[];
	checkout?: boolean;
}

export default (props: Props) => <MainRoot>{props.children}</MainRoot>;
