import React from 'react';
import { styled, Typography } from '@mui/material';

import Flex from 'components/Flex';

const BottomBarRoot = styled(Flex)(({ theme }) => ({
	background: theme.palette.primary.main,
	padding: theme.spacing(2, 3),
	width: '100%',
}));

const Text = styled(Typography)({
	color: 'white',
	fontWeight: 500,
	opacity: 1,
	textAlign: 'center',
});

export default () => {
	const currentYear = new Date();

	return (
		<BottomBarRoot direction="column" justify="center" align="center" defaultFlex>
			<Text variant="subtitle2">
				© {currentYear.getFullYear()} Atlas Scaffolding and Access Ltd.
			</Text>
		</BottomBarRoot>
	);
};
