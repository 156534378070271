import React, { useState, useEffect, useRef } from 'react';
import { styled, useTheme, useMediaQuery } from '@mui/material';

import Flex from 'components/Flex';
import atlasLogo from 'content/images/icons/atlas-logo.svg';
import Buttons from 'components/Nav/Buttons';
import MobileMenu from 'components/Nav/MobileMenu';

const NavRoot = styled(Flex, { shouldForwardProp: (prop) => prop !== 'dark' })<{ dark?: boolean }>(
	({ theme, dark }) => ({
		position: 'absolute',
		top: 0,
		zIndex: 99,
		background: dark ? theme.palette.text.primary : 'none',
		padding: theme.spacing(2, 20),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2, 10),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 3),
		},
	}),
);

const Logo = styled('img')(({ theme }) => ({
	width: 200,
	[theme.breakpoints.down('sm')]: {
		width: 150,
	},
}));

interface Props {
	dark?: boolean;
}

export default (props: Props) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const containerRef = useRef<HTMLDivElement>(null);
	const [anchorTop, setAnchorTop] = useState(0);
	const [anchorLeft, setAnchorLeft] = useState(0);

	const renderButtons = () => {
		if (mobile) {
			return (
				<MobileMenu
					key={anchorTop}
					anchorTop={anchorTop}
					anchorLeft={anchorLeft}
					containerRef={containerRef}
				/>
			);
		}
		return <Buttons />;
	};

	useEffect(() => {
		if (containerRef && containerRef.current) {
			const { height, width } = containerRef.current.getBoundingClientRect();
			setAnchorTop(height);
			setAnchorLeft(width / 2);
		}
	}, []);

	return (
		<NavRoot
			defaultFlex
			dark={props?.dark}
			width
			justify="space-between"
			ref={containerRef}
			align="center"
		>
			<Logo src={atlasLogo} />
			{renderButtons()}
		</NavRoot>
	);
};
