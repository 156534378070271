import React, { forwardRef } from 'react';

import { styled } from '@mui/material';

const Flex = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'justify' &&
		prop !== 'align' &&
		prop !== 'direction' &&
		prop !== 'defaultFlex' &&
		prop !== 'wrap' &&
		prop !== 'width',
})<{
	justify?: Properties['position'];
	align?: Properties['position'];
	direction?: Properties['direction'];
	defaultFlex?: boolean;
	wrap?: boolean;
	width?: boolean;
}>(({ justify, align, direction, defaultFlex, wrap, width }) => ({
	flex: defaultFlex ? 'default' : 1,
	display: 'flex',
	justifyContent: justify || 'flex-start',
	alignItems: align || 'flex-start',
	flexDirection: direction || 'row',
	flexWrap: wrap ? 'wrap' : 'nowrap',
	width: width ? '100%' : 'auto',
}));

interface Properties {
	position:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-around'
		| 'space-between'
		| 'space-evenly';
	direction: 'column' | 'row' | 'column-reverse' | 'row-reverse';
}

export interface FlexProps {
	className?: string;
	justify?: Properties['position'];
	align?: Properties['position'];
	direction?: Properties['direction'];
	defaultFlex?: boolean;
	wrap?: boolean;
	width?: boolean;
	id?: string;
}

interface Props extends FlexProps {
	children: React.ReactNode;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default forwardRef((props: Props, ref: React.ForwardedRef<HTMLDivElement>) => (
	<Flex
		className={props?.className}
		direction={props?.direction}
		justify={props?.justify}
		align={props?.align}
		defaultFlex={props?.defaultFlex}
		wrap={props?.wrap}
		ref={ref}
		onClick={props?.onClick}
		width={props?.width}
		id={props?.id}
	>
		{props?.children}
	</Flex>
));
