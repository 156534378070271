import React from 'react';
import { styled } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import Flex from 'components/Flex';
import Button from 'components/Button';

const NavButton = styled(Button)(({ theme }) => ({
	fontSize: 20,
	fontWeight: 'bold',
	margin: theme.spacing(0, 0, 0, 4),
	[theme.breakpoints.down('sm')]: {
		margin: theme.spacing(0, 0, 2),
		color: theme.palette.text.primary,
	},
}));

const Link = styled(AnchorLink)({
	textDecoration: 'none',
});

interface ButtonData {
	allWpNavigationLink: {
		nodes: {
			navigationLink: {
				pageUrl: string;
				sortOrder: number | null;
			};
			title: string;
		}[];
	};
}

interface Props {
	className?: string;
}

export default (props: Props) => {
	const buttonData: ButtonData = useStaticQuery(graphql`
		query NavLinks {
			allWpNavigationLink {
				nodes {
					navigationLink {
						pageUrl
						sortOrder
					}
					title
				}
			}
		}
	`);

	const sortedButtons = buttonData?.allWpNavigationLink?.nodes?.sort(
		(a, b) =>
			(a?.navigationLink?.sortOrder === null) - (b?.navigationLink?.sortOrder === null) ||
			+(a?.navigationLink?.sortOrder > b?.navigationLink?.sortOrder) ||
			-(a?.navigationLink?.sortOrder < b?.navigationLink?.sortOrder),
	);

	const renderButtons = () =>
		sortedButtons?.map((e) => (
			<Link to={`#${e?.navigationLink?.pageUrl}`}>
				<NavButton color="primary" variant="text" key={e?.title}>
					{e?.title}
				</NavButton>
			</Link>
		));

	return (
		<Flex defaultFlex align="center" className={props?.className}>
			{renderButtons()}
		</Flex>
	);
};
